.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-around {
  display: flex;
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}

.align-right {
  text-align: right;
}

.align-middle-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.align-bottom-flex {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
