:root {
  --red: #94393a;
  --green: #4a8042;
  --blue-light: #0a60b8;
  --blue: #004fa0;
  --light-blue: #ebf5ff;
  --grey-pastel: #e6e6e6;
  --grey-light: #acacac;
  --light-grey: #eeeeee;
  --grey: #777777;
  --grey-dark: #242323;
  --white: #ffffff;
  --black: #000000;

  --big-title: 25px;
  --page-title: 20px;
  --subtitle-text: 18px;
  --label: 16px;

  --big-title-xs: 18px;
  --page-title-xs: 16px;
  --subtitle-text-xs: 15px;
  --label-xs: 14px;

  --space: 20px;

  --xs: 4px;
  --s: 8px;
  --m: 12px;
  --l: 16px;
  --xl: 20px;

  // Variables
  // --------------------------

  --icons8-css-prefix: 'icons8';
  --icons8-font-name: 'airbus-home';
  --icons8-font-path: '../assets/fonts';

  --icons8-var-sort-down: '\f100';
  --icons8-var-delete: '\f101';
  --icons8-var-download: '\f102';
  --icons8-var-cancel: '\f103';
}
