/* You can add global styles to this file, and also import other style files */

@import './theme/variables.scss';
@import './theme/colors.scss';
@import './theme/fonts.scss';
@import './theme/space.scss';
@import './theme/flexbox.scss';
@import './theme/core.scss';
@import './theme/path.scss';
@import './theme/icons.scss';
@import './theme/radio.scss';

body {
  background-color: #ebebeb;
  font-family: 'Livvic', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
}

html,
body {
  height: 100%;
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}

.underlined {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.selectable:hover {
  cursor: pointer;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium-weight {
  font-weight: 500;
}

.header-button {
  font-size: 28px;
}

.normal-weight {
  font-weight: 400;
}

.italic {
  font-style: italic;
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.nowrap {
  white-space: nowrap;
}

.border-radius {
  border-radius: 5px;
}

.border-radius-left-top {
  border-radius: 5px 0 0 0;
}

.border-radius-right-top {
  border-radius: 0 5px 0 0;
}

.border-radius-right-bottom {
  border-radius: 0 0 5px 0;
}

.border-radius-left-bottom {
  border-radius: 0 0 0 5px;
}

.pointer-none {
  pointer-events: none;
}

.underline {
  text-decoration: underline;
}

.underline-title {
  color: var(--blue);
  font-size: var(--page-title);
  font-weight: 700;
  border-bottom: 1px solid var(--blue);
  margin-top: 2vh;

  .create-icon {
    font-size: 30px;
  }
}

.margin-top {
  margin-top: 20px;
}

.plus-title {
  margin-top: 1vh;
  align-items: center;
}

.page {
  min-height: 0;
  height: 100%;
  padding: 0 4vw;
}

@media screen and (min-width: 900px) {
  .page {
    padding: 0 25vw;
  }
}

.page-space {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 0;
}

.page-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.popup-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.space-top {
  margin-top: var(--space);
}

.space-top-bottom {
  margin-top: var(--space);
  margin-bottom: var(--space);
}

.visibility-hidden {
  visibility: hidden;
}

.justify-center {
  justify-content: center;
}

/********************************************/
/****************** INPUTS ******************/
/********************************************/
form {
  overflow-y: auto;
  height: 100%;
}

form::-webkit-scrollbar {
  background-color: var(--black-light);
  width: 5px;
}

form::-webkit-scrollbar:horizontal {
  height: 0px;
}

form::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--grey-light);
}

.input-text {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.26);
  font-size: var(--label);
  padding: 4px 8px;
}

.input-text:focus {
  outline: none !important;
  box-shadow: 0 0 3px var(--blue-light);
  border-color: var(--blue-light);
}

.input-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.label-container {
  padding: 2.23vh var(--space) 2.23vh 0;
}

/********************************************/
/****************** POPUP ******************/
/********************************************/

.modal-background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: #2d2d2d;
  opacity: 0.3;
  z-index: 999;
}

.modal-container {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.modal {
  border-radius: 5px;
  pointer-events: visible;
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 20%;
  z-index: 1000;
  padding: 2vh 20px;
  color: var(--white);
  text-align: center;
  box-shadow: 0 0 30px 0 rgba(70, 70, 70, 0.87);
}

.modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.modal-content::-webkit-scrollbar {
  background-color: var(--white);
  width: 5px;
}

.modal-content::-webkit-scrollbar:horizontal {
  height: 0px;
}

.modal-content::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--grey-pastel);
}

.scroll::-webkit-scrollbar {
  background-color: var(--white);
  width: 5px;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--grey-pastel);
}

/********************************************/
/****************** INPUTS ******************/
/********************************************/

.flex-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.secondary-button {
  margin: 4px 0;
  padding: 8px;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}

input {
  font-family: 'Livvic';
}

.input-login {
  margin: 8px 0;
  color: black;
  display: flex;
  align-items: stretch;
  margin-bottom: 15px;
}

.input-login i {
  color: var(--grey);
}

.input-login input {
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  color: var(--black);
  font-size: var(--big-title-text);
  padding: 10px;
  outline: none;
}

.clear {
  width: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 1px solid var(--grey-light);
  font-size: 28px;
  size: auto;
  cursor: pointer;
}

@media (max-width: 550px) and (orientation: portrait) {
  .clear {
    width: 30px;
    font-size: 18px;
    padding: 0 5px;
  }
}

.align-left {
  text-align: left;
}

/********************************************/
/****************** BUTTONS ******************/
/********************************************/

.button {
  cursor: pointer;
  background-color: var(--blue);
  color: var(--white);
  margin: var(--l) auto 0;
  border-radius: 5px;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.4);
  width: 250px;
  transition: all 0.3s;
  i {
    font-size: 22px;
  }
}

.button:hover {
  background-color: var(--blue-light);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 900px) {
  .button {
    box-sizing: border-box;
    width: 98%;
  }
}
