.radio-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 4px;
}

.radio-container div {
  padding: 8px 0;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

[type="radio"]:checked + label {
  color: var(--blue);
}

[type="radio"]:not(:checked) + label {
  color: var(--grey-dark);
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:not(:checked) + label:before {
  border: 1px solid var(--grey);
}

[type="radio"]:checked + label:before {
  border: 1px solid var(--blue);
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--blue);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 550px) and (orientation: portrait) {
  .radio-container {
    flex-direction: column;
  }
}
