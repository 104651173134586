// Icons
// -------------------------

.icons8-sort-down:before,
.icons8-delete:before,
.icons8-download:before,
.icons8-cancel:before,
[data-icons8]:before {
  @include icons8-font;
}

[data-icons8]:before {
  content: attr(data-icons8);
}

.icons8-sort-down:before {
  content: var(--icons8-var-sort-down);
}
.icons8-delete:before {
  content: var(--icons8-var-delete);
}
.icons8-download:before {
  content: var(--icons8-var-download);
}
.icons8-cancel:before {
  content: var(--icons8-var-cancel);
}
