.big-title {
  font-size: var(--big-title);
}

.subtitle-text {
  font-size: var(--subtitle-text);
}

.page-title {
  font-size: var(--page-title);
}

.label {
  font-size: var(--label);
}

@media screen and (max-width: 1100px) {
  .big-title {
    font-size: var(--big-title-xs);
  }

  .subtitle-text {
    font-size: var(--subtitle-text-xs);
  }

  .page-title {
    font-size: var(--page-title-xs);
  }

  .label {
    font-size: var(--label-xs);
  }
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 100;
  src: url("../assets/fonts/Livvic/Livvic-ThinItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Livvic/Livvic-Thin.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/Livvic/Livvic-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Livvic/Livvic-SemiBold.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Livvic/Livvic-Regular.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/Livvic/Livvic-MediumItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Livvic/Livvic-Medium.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/Livvic/Livvic-LightItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Livvic/Livvic-Light.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/Livvic/Livvic-Italic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/Livvic/Livvic-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/Livvic/Livvic-ExtraLight.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/Livvic/Livvic-BoldItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Livvic/Livvic-Bold.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: italic;
  font-weight: 900;
  src: url("../assets/fonts/Livvic/Livvic-BlackItalic.ttf");
}

@font-face {
  font-family: "Livvic";
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/Livvic/Livvic-Black.ttf");
}
