// Font paths
// -------------------------

@font-face {
  font-family: "airbus-home";
  src: url("../assets/fonts/icons/airbus-home.eot");
  src: url("../assets/fonts/icons/airbus-home.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icons/airbus-home.woff2") format("woff2"),
    url("../assets/fonts/icons/airbus-home.woff") format("woff"),
    url("../assets/fonts/icons/airbus-home.ttf") format("truetype"),
    url("../assets/fonts/icons/airbus-home.svg#font") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "airbus-home";
    src: url("../assets/fonts/icons/airbus-home.svg#airbus-home") format("svg");
  }
}
