.margin-xl {
  margin: var(--xl) !important;
}

.margin-left-xl {
  margin-left: var(--xl) !important;
}

.margin-right-xl {
  margin-right: var(--xl) !important;
}

.margin-top-xl {
  margin-top: var(--xl) !important;
}

.margin-bottom-xl {
  margin-bottom: var(--xl) !important;
}

.padding-xl {
  padding: var(--xl) !important;
}

.padding-left-xl {
  padding-left: var(--xl) !important;
}

.padding-right-xl {
  padding-right: var(--xl) !important;
}

.padding-top-xl {
  padding-top: var(--xl) !important;
}

.padding-bottom-xl {
  padding-bottom: var(--xl) !important;
}

.margin-l {
  margin: var(--l) !important;
}

.margin-left-l {
  margin-left: var(--l) !important;
}

.margin-right-l {
  margin-right: var(--l) !important;
}

.margin-top-l {
  margin-top: var(--l) !important;
}

.margin-bottom-l {
  margin-bottom: var(--l) !important;
}

.padding-l {
  padding: var(--l) !important;
}

.padding-left-l {
  padding-left: var(--l) !important;
}

.padding-right-l {
  padding-right: var(--l) !important;
}

.padding-top-l {
  padding-top: var(--l) !important;
}

.padding-bottom-l {
  padding-bottom: var(--l) !important;
}

.margin-m {
  margin: var(--m) !important;
}

.margin-left-m {
  margin-left: var(--m) !important;
}

.margin-right-m {
  margin-right: var(--m) !important;
}

.margin-top-m {
  margin-top: var(--m) !important;
}

.margin-bottom-m {
  margin-bottom: var(--m) !important;
}

.padding-m {
  padding: var(--m) !important;
}

.padding-left-m {
  padding-left: var(--m) !important;
}

.padding-right-m {
  padding-right: var(--m) !important;
}

.padding-top-m {
  padding-top: var(--m) !important;
}

.padding-bottom-m {
  padding-bottom: var(--m) !important;
}

.margin-s {
  margin: var(--s) !important;
}

.margin-left-s {
  margin-left: var(--s) !important;
}

.margin-right-s {
  margin-right: var(--s) !important;
}

.margin-top-s {
  margin-top: var(--s) !important;
}

.margin-bottom-s {
  margin-bottom: var(--s) !important;
}

.padding-s {
  padding: var(--s) !important;
}

.padding-left-s {
  padding-left: var(--s) !important;
}

.padding-right-s {
  padding-right: var(--s) !important;
}

.padding-top-s {
  padding-top: var(--s) !important;
}

.padding-bottom-s {
  padding-bottom: var(--s) !important;
}

.padding-bottom-xs {
  padding-bottom: var(--xs) !important;
}

.padding-top-xs {
  padding-top: var(--xs) !important;
}

.padding-left-xs {
  padding-left: var(--xs) !important;
}

.padding-right-xs {
  padding-right: var(--xs) !important;
}

.padding-xs {
  padding: var(--xs) !important;
}

.margin-left-xs {
  margin-left: var(--xs);
}

.margin-right-xs {
  margin-right: var(--xs);
}

.margin-top-xs {
  margin-top: var(--xs);
}

.margin-bottom-xs {
  margin-bottom: var(--xs);
}

.margin-xs {
  margin: var(--xs);
}
