.red {
  color: var(--red);
}
.green {
  color: var(--green);
}
.blue-light {
  color: var(--blue-light);
}
.blue {
  color: var(--blue);
}
.grey-pastel {
  color: var(--grey-pastel);
}
.grey-light {
  color: var(--grey-light);
}
.grey {
  color: var(--grey);
}
.grey-dark {
  color: var(--grey-dark);
}
.white {
  color: var(--white);
}
.black {
  color: var(--black);
}

.bg-red {
  background-color: var(--red);
}
.bg-green {
  background-color: var(--green);
}
.bg-blue-light {
  background-color: var(--blue-light);
}
.bg-blue {
  background-color: var(--blue);
}
.bg-light-blue {
  background-color: var(--light-blue);
}
.bg-grey-pastel {
  background-color: var(--grey-pastel);
}
.bg-grey-light {
  background-color: var(--grey-light);
}
.bg-light-grey {
  background-color: var(--light-grey);
}
.bg-grey {
  background-color: var(--grey);
}
.bg-grey-dark {
  background-color: var(--grey-dark);
}
.bg-white {
  background-color: var(--white);
}

.border-blue {
  border: 1px solid var(--blue);
}

.border-green {
  border: 1px solid var(--green);
}

.border-red {
  border: 1px solid var(--red);
}

.border-grey {
  border: 1px solid var(--grey);
}

.border-grey-light {
  border: 1px solid var(--grey-light);
}

.border-bold-green {
  border: 2px solid var(--green);
}

.border-bold-red {
  border: 2px solid var(--red);
}
